@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --rsbs-backdrop-bg: rgba(0, 0, 0, 0.6);
  --rsbs-bg: #f8f8fd;
  --rsbs-handle-bg: hsla(0, 0%, 0%, 0.14);
  --rsbs-handle-bg-header: #d9d9dd;
  --rsbs-max-w: auto;
  --rsbs-ml: env(safe-area-inset-left);
  --rsbs-mr: env(safe-area-inset-right);
  --rsbs-overlay-rounded: 1.6rem;
}

html.lenis,
html.lenis body {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto !important;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-smooth iframe {
  pointer-events: none;
}

iframe {
  width: 100%;
  height: 100%;
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  padding: 0;
  margin: 0;
  width: 100%;
  font-size: 10px;
}

html,
body {
  height: 100%;
}

select {
  appearance: none;
}

body {
  padding: 0;
  margin: 0;
  width: 100%;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  @apply text-cBlack;
  @apply bg-white;
  min-width: 360px;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.2;
  -webkit-overflow-scrolling: touch;
  position: relative;
  @apply font-base;
}

* {
  box-sizing: border-box;

  &:after {
    box-sizing: border-box;
  }

  &:before {
    box-sizing: border-box;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

p {
  margin: 0;
  padding: 0;
}

ul {
  margin: 0;
  padding: 0 0 0 2rem;
  list-style: outside url('/icons/list-marker.svg');
}

ol {
  margin: 0;
  padding: 0;
}

address {
  margin: 0;
  padding: 0;
  font-style: normal;
}

figure {
  margin: 0;
  padding: 0;
}

table {
  margin: 0;
  padding: 0;
}

b {
  font-weight: 700;
}

strong {
  font-weight: 700;
}

@supports (font: -apple-system-body) and (-webkit-appearance: none) {
  img[loading='lazy'] {
    clip-path: inset(0.06rem);
  }
}

@keyframes spinSlow {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinBtnLoader {
  0% {
    rotate: 0deg;
  }

  100% {
    rotate: 360deg;
  }
}

@keyframes loadingSpin {
  0%,
  100% {
    transform: translate(0);
  }

  25% {
    transform: translate(160%);
  }

  50% {
    transform: translate(160%, 160%);
  }

  75% {
    transform: translate(0, 160%);
  }
}

.map-marker {
  width: 3.6rem;
  height: 5.6rem;
  position: relative;
  top: -5.6rem;
  left: 50%;
  margin-left: -1.8rem;
}

.map-marker--cdek {
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: url('/icons/cdek.svg') no-repeat 0 0;
    background-size: contain;
  }
}

.map-marker--market {
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: url('/icons/market.svg') no-repeat 0 0;
    background-size: contain;
  }
}

.map-marker--pochta {
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: url('/icons/pochta.svg') no-repeat 0 0;
    background-size: contain;
  }
}

.map-marker__count {
  position: absolute;
  left: 50%;
  bottom: 100%;
  transform: translateX(-50%);
  white-space: nowrap;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 148%;
  padding: 0 0.8rem;
  padding-left: 3rem;
  margin-bottom: 0.8rem;
  border-radius: 0.8rem;
  background-color: #ffb016;
  color: #fff;
}

.map-marker__count img {
  position: absolute;
  width: 1.6rem;
  height: 1.6rem;
  left: 0.8rem;
  top: 50%;
  margin-top: -0.8rem;
  object-fit: contain;
  object-position: center;
}

.map-marker__count--all {
  background-color: #3dc869;
}

[data-rsbs-overlay] {
  border-top-left-radius: var(--rsbs-overlay-rounded);
  border-top-right-radius: var(--rsbs-overlay-rounded);
  display: flex;
  background: var(--rsbs-bg);
  flex-direction: column;
  height: var(--rsbs-overlay-h);
  transform: translate3d(0, var(--rsbs-overlay-translate-y), 0);
  will-change: height;
}

[data-rsbs-overlay]:focus {
  outline: none;
}

[data-rsbs-is-blocking='false'] [data-rsbs-overlay] {
  box-shadow:
    0 -0.5rem 6rem 0 rgba(38, 89, 115, 0.11),
    0 -0.1rem 0 rgba(38, 89, 115, 0.05);
}

[data-rsbs-overlay],
[data-rsbs-root]:after {
  max-width: var(--rsbs-max-w);
  margin-left: var(--rsbs-ml);
  margin-right: var(--rsbs-mr);
}

[data-rsbs-overlay],
[data-rsbs-backdrop],
[data-rsbs-root]:after {
  z-index: 201;
  overscroll-behavior: none;
  touch-action: none;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

[data-rsbs-backdrop] {
  top: -6rem;
  bottom: -6rem;
  background-color: var(--rsbs-backdrop-bg);
  will-change: opacity;
  cursor: pointer;
  opacity: 1;
}

[data-rsbs-is-dismissable='false'] [data-rsbs-backdrop] {
  cursor: ns-resize;
}

[data-rsbs-root]:after {
  content: '';
  pointer-events: none;
  background: var(--rsbs-bg);
  height: 1px;
  transform-origin: bottom;
  transform: scale3d(1, var(--rsbs-antigap-scale-y), 1);
  will-change: transform;
}

[data-rsbs-footer],
[data-rsbs-header] {
  flex-shrink: 0;
  cursor: ns-resize;
  padding: 1.6rem;
}

[data-rsbs-header] {
  text-align: center;
  user-select: none;
  box-shadow: 0 1px 0 rgba(46, 59, 66, calc(var(--rsbs-content-opacity) * 0.125));
  z-index: 1;
  padding-top: calc(2rem + env(safe-area-inset-top));
  padding-bottom: 0.8rem;
}

[data-rsbs-header]:before {
  position: absolute;
  content: '';
  display: block;
  width: 3.6rem;
  height: 0.4rem;
  top: calc(0.8rem + env(safe-area-inset-top));
  left: 50%;
  transform: translateX(-50%);
  border-radius: 0.2rem;
  background-color: var(--rsbs-handle-bg);
}

@media (min-resolution: 2dppx) {
  [data-rsbs-header]:before {
    transform: translateX(-50%) scaleY(0.75);
  }
}

[data-rsbs-has-header='false'] [data-rsbs-header] {
  box-shadow: none;
  padding-top: calc(1.2rem + env(safe-area-inset-top));
}

[data-rsbs-scroll] {
  flex-shrink: 1;
  flex-grow: 1;
  -webkit-tap-highlight-color: revert;
  -webkit-touch-callout: revert;
  -webkit-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
  overflow: auto;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
}

[data-rsbs-scroll]:focus {
  outline: none;
}

[data-rsbs-has-footer='false'] [data-rsbs-content] {
  padding-bottom: env(safe-area-inset-bottom);
}

[data-rsbs-content] {
  /* The overflow hidden is to ensure any margin on child nodes are included when the resize observer is measuring the height */
  overflow: hidden;
}

[data-rsbs-footer] {
  box-shadow:
    0 -0.1rem 0 rgba(46, 59, 66, calc(var(--rsbs-content-opacity) * 0.125)),
    0 0.2rem 0 var(--rsbs-bg);
  overflow: hidden;
  z-index: 1;
  padding-bottom: calc(1.6rem + env(safe-area-inset-bottom));
}

[data-rsbs-is-dismissable='true'],
[data-rsbs-is-dismissable='false']:matches([data-rsbs-state='opening'], [data-rsbs-state='closing']) {
  & :matches([data-rsbs-header], [data-rsbs-scroll], [data-rsbs-footer]) > * {
    opacity: var(--rsbs-content-opacity);
  }

  & [data-rsbs-backdrop] {
    opacity: var(--rsbs-backdrop-opacity);
  }
}

[data-rsbs-state='closed'],
[data-rsbs-state='closing'] {
  /* Allows interactions on the rest of the page before the close transition is finished */
  pointer-events: none;
}

[data-rsbs-overlay] {
  border: 0;
  box-shadow: 0 0.2rem 1.6rem 0 rgba(25, 27, 35, 0.06);
}

reach-portal {
  /* position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: auto; */
}

.swiper-pagination {
  font-size: 0;
  line-height: 1;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 3.2rem;
  top: auto;
}

.swiper-pagination-bullet {
  width: 0.6rem;
  height: 0.6rem;
  background-color: #fff;
  opacity: 0.64;
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 0.4rem;
}

.swiper-pagination-bullet-active {
  opacity: 1;
}

.swiper-wrapper {
  will-change: transform;
}

.iiz {
  cursor: url('/icons/cursor_plus.svg'), auto !important;
  overflow: visible !important;
}

.iiz__zoom-img--visible {
  cursor: url('/icons/cursor_minus.svg'), auto !important;
}

.iiz__zoom-img {
  height: revert-layer;
}

.iiz__img {
  height: revert-layer;
}

.aboveAllPortal {
  position: absolute;
  z-index: 1000;
}

.aboveABitMorePortal {
  position: absolute;
  z-index: 90;
}

.FiltersPopupStyles-cvaScroll::-webkit-scrollbar {
  width: 4px;
}

.CallbackPopupStyles-cvaInner::-webkit-scrollbar {
  width: 4px;
}

.CallbackPopupStyles-cvaInner::-webkit-scrollbar-thumb {
  background-color: #e9e9f3; /* Цвет как на твоей картинке */
  border-radius: 15px;
}

.CallbackPopupStyles-cvaInner::-webkit-scrollbar-thumb {
  background-color: #e9e9f3; /* Цвет как на твоей картинке */
  border-radius: 15px;
  margin-right: 1px;
}

.FiltersPopupStyles-cvaScroll::-webkit-scrollbar-thumb {
  background-color: #e9e9f3; /* Цвет как на твоей картинке */
  border-radius: 15px;
}

.StoresMapStyles-cvaAsideScroll::-webkit-scrollbar-thumb {
  background-color: #e9e9f3; /* Цвет как на твоей картинке */
  border-radius: 15px;
  margin-right: 1px;
}

.StoresMapStyles-cvaAsideScroll::-webkit-scrollbar {
  width: 8px;
}

.StoresMapStyles-cvaAsideScroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.SearchPopupStyles-cvaScroll::-webkit-scrollbar-thumb {
  background-color: #e9e9f3; /* Цвет как на твоей картинке */
  border-radius: 15px;
  margin-right: 1px;
}

.SearchPopupStyles-cvaScroll::-webkit-scrollbar {
  width: 8px;
}

.SearchPopupStyles-cvaScroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.PvzMapStyles-cvaAsideScroll::-webkit-scrollbar-thumb {
  background-color: #e9e9f3; /* Цвет как на твоей картинке */
  border-radius: 15px;
  margin-right: 1px;
}

.PvzMapStyles-cvaAsideScroll::-webkit-scrollbar {
  width: 8px;
}

.PvzMapStyles-cvaAsideScroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.FiltersPopupStyles-cvaScroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.CheckboxesGroupStyles-cvaItems::-webkit-scrollbar {
  width: 4px;
}

.CheckboxesGroupStyles-cvaItems::-webkit-scrollbar-thumb {
  background-color: #e9e9f3; /* Цвет как на твоей картинке */
  border-radius: 10px;
}

.CheckboxesGroupStyles-cvaItems::-webkit-scrollbar-track {
  background-color: transparent;
}

.CheckboxesGroupStyles-cvaGroups::-webkit-scrollbar {
  width: 4px;
}

.CheckboxesGroupStyles-cvaGroups::-webkit-scrollbar-thumb {
  background-color: #e9e9f3; /* Цвет как на твоей картинке */
  border-radius: 10px;
}

.CheckboxesGroupStyles-cvaGroups::-webkit-scrollbar-track {
  background-color: transparent;
}

.simplebar-track.simplebar-vertical {
  width: 4px;
  right: 2px;
}

.simplebar-scrollbar:before {
  background: #e9e9f3;
  border-radius: 10px;
  width: 4px;
}

.text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  align-self: stretch;
  word-break: break-word;
}

.ProductPreviewCardBase-cvaNameContainer {
  display: flex;
  flex-direction: column;
}

.PvzMapStyles-cvaSheet [data-rsbs-header],
.StoresMapStyles-cvaSheet [data-rsbs-header] {
  padding: 0;
  box-shadow: none;
}

.PvzMapStyles-cvaSheet [data-rsbs-header]:before,
.StoresMapStyles-cvaSheet [data-rsbs-header]:before {
  display: none;
}

.PvzMapStyles-cvaBottomSheetHeader,
.StoresMapStyles-cvaBottomSheetHeader {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 0.4rem;
  padding-top: calc(0.4rem + env(safe-area-inset-top));
  padding-bottom: 0.8rem;
}

.PvzMapStyles-cvaBottomSheetHeader::before,
.PvzMapStyles-cvaBottomSheetHeader::after,
.StoresMapStyles-cvaBottomSheetHeader::before,
.StoresMapStyles-cvaBottomSheetHeader::after {
  content: '';
  content: '';
  display: block;
  width: 1.2rem;
  height: 0.2rem;
  background-color: var(--rsbs-handle-bg-header);
  transition: transform 0s ease 0.2s;
}

.PvzMapStyles-cvaBottomSheetHeader::before,
.StoresMapStyles-cvaBottomSheetHeader::before {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.PvzMapStyles-cvaBottomSheetHeader::after,
.StoresMapStyles-cvaBottomSheetHeader::after {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.PvzMapStyles-cvaBottomSheetHeader--bottom::before,
.StoresMapStyles-cvaBottomSheetHeader--bottom::before {
  transform-origin: top right;
  transform: rotate(-10deg);
}

.PvzMapStyles-cvaBottomSheetHeader--bottom::after,
.StoresMapStyles-cvaBottomSheetHeader--bottom::after {
  transform-origin: top left;
  transform: rotate(10deg);
}

@media (max-width: 767px) {
  input[type='email'],
  input[type='number'],
  input[type='password'],
  input[type='search'],
  input[type='tel'],
  input[type='text'],
  textarea {
    font-size: max(16px, 1.3rem);
  }
}

.Slide1-cvaImageMask {
  position: relative;
  mask-image: url('/images/mask-banner.svg');
  mask-repeat: no-repeat;
  mask-size: 100% 100%;
  width: 50%;
  flex: 0 0 auto;
  height: 67.4rem;
}

.Slide1-cvaContent {
  mask-image: url('/images/mask-banner.svg');
  mask-repeat: no-repeat;
  mask-size: 100% 100%;
  width: 50%;
  flex: 0 0 auto;
}

.Slide1-cvaDecorateBg {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  width: 20rem;
}

@media (max-width: 1023px) {
  .Slide1-cvaImageMask {
    position: relative;
    mask-image: none;
    width: 100%;
    flex: 0 0 auto;
    height: 67.4rem;
  }

  .Slide1-cvaContent {
    mask-image: none;
    width: 100%;
    flex: 1 1 50%;
  }

  .Slide1-cvaDecorateBg {
    display: none;
  }
}

@media (max-width: 767px) {
  .Slide1-cvaImageMask {
    height: 32.4rem;
  }
}
